import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import RecommendationHome from "../components/recommendation-home"

const Index = ({ data, location }) => {

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array
  }

  const siteTitle = data.site.siteMetadata?.title || `Title`
  
  const bricksAll = shuffleArray(data.bricksAll.edges)
  const bricksTechnic = shuffleArray(data.bricksTechnic.edges)
  // const eyecare = shuffleArray(data.eyecare.edges)
  // const serum = shuffleArray(data.serum.edges)
  // const makeup = shuffleArray(data.makeup.edges)
  // const masks = shuffleArray(data.masks.edges)
  

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet>
        <title>Collective Bricks - Newest and latest bricks - your ultimate Lego and Technic destination.  </title>
        <link rel="canonical" href="https://www.collectivebricks.com/" />      
        <meta name="description" content="Discover the world of Lego with Collective Bricks, your complete Lego marketplace. Our mission is to provide an all-in-one platform where Lego enthusiasts can find and explore the best and top-rated Lego sets available in the industry. Regardless if you're a collector or a beginner, we aim to cater to your needs and provide the best Lego shopping experience. Experience the world of amazing Lego sets at your fingertips with Collective Bricks."/>
  
  <script type="application/ld+json">
          {`
            {
              "@context": "http://www.schema.org",
              "@type": "Organization",
              "name": "Collective Bricks",
              "url": "https://www.collectivebricks.com",
              "logo": "https://www.collectivebricks.com/static/4dcefb04f813c8b4dfc1c8dfe427f5e8/8acde/logo-crop.avif",
              "image": "https://www.collectivebricks.com/static/4dcefb04f813c8b4dfc1c8dfe427f5e8/8acde/logo-crop.avif",
              "description": "Discover the world of Lego with Collective Bricks, your complete Lego marketplace. Our mission is to provide an all-in-one platform where Lego enthusiasts can find and explore the best and top-rated Lego sets available in the industry. Regardless if you're a collector or a beginner, we aim to cater to your needs and provide the best Lego shopping experience. Experience the world of amazing Lego sets at your fingertips with Collective Bricks."
            }
        `}
        </script>
      </Helmet>
      <div>
       
      <h1 class="home-intro">Welcome to Collective Bricks, your comprehensive solution for all your Lego needs. Our aim is to provide a one-stop destination where you can explore the finest Lego sets available in the market..</h1>
      <h2 class="home-intro">Choosing the perfect set can be an overwhelming experience, which is why we carefully curate a selection of top-notch Lego products from different online platforms around the globe. No more browsing through countless websites, at Collective Bricks, we aim to showcase only the best and save you the hassle and time. Discover and indulge your passion for Lego with us!</h2>
      
      <RecommendationHome data={bricksAll} name="All Lego Sets" collection="all"/>
      <RecommendationHome data={bricksTechnic} name="Lego Technic"  collection="technic"/>
      {/* <RecommendationHome data={eyecare} name="Eyecare" collection="eyecare"/>
      <RecommendationHome data={serum} name="Serum" collection="serum"/>
      <RecommendationHome data={makeup} name="Makeup" collection="makeup"/>
      <RecommendationHome data={masks} name="Masks" collection="masks"/> */}
      </div>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    bricksAll: allAirtable(limit: 50, filter: { table: { eq: "bricks_all" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description_gpt
          }
        }
      }
    }
    bricksTechnic: allAirtable(limit: 50, filter: { table: { eq: "bricks_technic" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description_gpt
          }
        }
      }
    }        
  }
`